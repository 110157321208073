import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

function PrivateRoute({ children }) {
  const [isAuth, setIsAuth] = useState(false);
  const connectedPages = localStorage.getItem("connectedPages");

  const userId = localStorage.getItem("userId");

  const navigate = useNavigate();

  useEffect(() => {
    if (!userId || userId === null) {
      navigate("/");
    } else if (!connectedPages || connectedPages === "{}") {
      navigate("/connect");
    }
    setIsAuth(true);
  }, [navigate, userId, connectedPages]);

  return isAuth && children;
}

export default PrivateRoute;
