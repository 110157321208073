import React, { useState } from "react";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { DevTool } from "@hookform/devtools";
import { yupResolver } from "@hookform/resolvers/yup";
import FormProvider, { RHFTextField } from "../components/hook-form";
import { forgotPassword } from "../helpers/apiAuth";
import Iconify from "../components/iconify";

import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import Alert from "@mui/material/Alert";

// ----------------------------------------------------------------------

export default function ForgotPasswordNew() {
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState("");

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email is required")
      .email("Email must be a valid email address"),
  });

  const defaultValues = {
    email: "",
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    control,
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    const email = data.email;
    try {
      const res = await forgotPassword({ email });
      const { status } = res;
      if (status === "success") {
        setSuccessMessage(
          "Password reset email sent. Please check your email."
        );
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        setErrorMessage(error.message);
      }
    }
  });

  const renderForm = (
    <Stack spacing={2.5}>
      <Typography variant="h4">Forgot password?</Typography>
      <RHFTextField name="email" label="Email address" />

      <LoadingButton
        fullWidth
        color="inherit"
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
        endIcon={<Iconify icon="eva:arrow-ios-forward-fill" />}
        sx={{ justifyContent: "space-between", pl: 2, pr: 1.5 }}
      >
        Request password reset
      </LoadingButton>

      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      {successMessage && <Alert severity="success">{successMessage}</Alert>}
    </Stack>
  );

  const renderEnd = (
    <Link
      // href="/auth/login-new"
      href="/"
      variant="body2"
      color="primary"
      sx={{ alignSelf: "center", marginTop: "10px" }}
    >
      &#8592; Back to Login
    </Link>
  );

  return (
    <>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        {renderForm}
      </FormProvider>
      {renderEnd}
      <DevTool control={control} />
    </>
  );
}
