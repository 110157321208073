import { createContext, useState } from "react";
import { useQuery } from "@tanstack/react-query";

import { getPage } from "../helpers/apiPage";

import { IPageContext } from "../types/page.type";

const PageContext = createContext({} as IPageContext);

export const PageProvider = ({ children }: { children: React.ReactNode }) => {
  const [pageId, setPageId] = useState(
    localStorage.getItem("defaultPage") || ""
  );
  const { isLoading: pageIsLoading, data } = useQuery({
    queryKey: ["page", pageId],
    queryFn: () => getPage({ pageId }),
    retry: false,
  });

  return (
    <PageContext.Provider
      value={{
        pageId,
        setPageId,
        pageIsLoading,
        fbPage: data && data.data.page,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

export default PageContext;
