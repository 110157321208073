import { useContext, useState, useEffect, useCallback } from "react";

import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import {
  Avatar,
  CardHeader,
  Link,
  TextField,
  Typography,
  useTheme,
  Tabs,
  Tab,
  Box,
} from "@mui/material";

import CustomBreadcrumbs from "../components/custom-breadcrumbs";
import Iconify from "../components/iconify";
import { useSnackbar } from "../components/snackbar";
import Spinner from "../components/Spinner";

import PageContext from "../context/pageContext";
import { updateReplyText } from "../helpers/apiPage";

import { IPageContext } from "../types/page.type";

import { useQueryClient, useMutation } from "@tanstack/react-query";
// ----------------------------------------------------------------------

export default function GrowthNew() {
  const { pageId, fbPage } = useContext(PageContext) as IPageContext;
  const queryClient = useQueryClient();
  const [pageReplyText, setPageReplyText] = useState<string>("");
  const [editPageReplyText, setEditPageReplyText] = useState(false);
  const [isPageReplyInputValid, setIsPageReplyInputValid] = useState(true);
  const [currentTab, setCurrentTab] = useState("Standard");
  const TABS = [
    {
      label: "Standard",
      subscriptionLink: `https://m.me/rn/${pageId}?topic=Subscribe`,
    },
    {
      label: "Klaviyo",
      subscriptionLink: `https://m.me/rn/${pageId}?topic=Subscribe&ref={{ email|default:'' }}`,
    },
    {
      label: "Attentive",
      subscriptionLink: `https://m.me/rn/${pageId}?topic=Subscribe&ref={{subscriber.email}}`,
    },
  ];
  const { enqueueSnackbar } = useSnackbar();

  const theme = useTheme();

  useEffect(() => {
    if (fbPage) {
      setPageReplyText(fbPage.replyText);
    }
  }, [fbPage]);

  const handleChangeTab = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      setCurrentTab(newValue);
    },
    []
  );

  const handleCopyToClipboard = (textToCopy: string) => {
    navigator.clipboard.writeText(textToCopy);
    enqueueSnackbar("Link copied to clipboard.");
  };

  const onPageReplyTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setPageReplyText(e.target.value);
    const isValid = e.target.value.length <= 2000;
    setIsPageReplyInputValid(isValid);
    if (!isValid) {
      enqueueSnackbar("Reply text should be less than 2000 characters.", {
        variant: "error",
      });
    }
  };

  const updateReplyTextMutation = useMutation({
    mutationFn: () =>
      updateReplyText({
        pageId,
        replyText: pageReplyText,
      }),
    onSuccess: () => {
      setEditPageReplyText(false);
      enqueueSnackbar("Reply text saved.");
      queryClient.invalidateQueries({ queryKey: ["page"] });
    },
  });

  const handlePageReplyTextSave = async (e: React.MouseEvent<HTMLElement>) => {
    try {
      e.preventDefault();
      updateReplyTextMutation.mutate();
    } catch {
      console.log("Failed to save reply text.");
    }
  };

  const renderSubscriptionLink = (
    <Card>
      <CardHeader title="Subscription Link" />

      <Stack spacing={2.5} sx={{ p: 3 }}>
        <Tabs value={currentTab} onChange={handleChangeTab}>
          {TABS.map((tab) => (
            <Tab key={tab.label} value={tab.label} label={tab.label} />
          ))}
        </Tabs>
        {TABS.map(
          (tab) =>
            tab.label === currentTab && (
              <Stack
                key={tab.label}
                spacing={3}
                direction={{ xs: "column", sm: "row" }}
                alignItems={{ xs: "flex-start", sm: "center" }}
                justifyContent={{ xs: "flex-start", sm: "space-between" }}
              >
                <Box
                  sx={{
                    p: 2,
                    borderRadius: 1,
                    bgcolor: "background.neutral",
                    flexGrow: 1,
                  }}
                >
                  <Typography variant="body2">
                    {tab.subscriptionLink}
                  </Typography>
                </Box>
                <Button
                  size="small"
                  startIcon={<Iconify icon="mdi:clipboard" />}
                  onClick={() => handleCopyToClipboard(tab.subscriptionLink)}
                  sx={(theme) => ({
                    p: 2,
                    border: 1,
                    borderColor: theme.palette.grey[500],
                    minWidth: "125px",
                    maxWidth: "125px",
                    marginLeft: 0,
                  })}
                >
                  Copy Link
                </Button>
              </Stack>
            )
        )}
      </Stack>
    </Card>
  );

  const renderReplyText = (
    <Card>
      <CardHeader title="Subscription Reply Text" />

      <Stack spacing={2.5} sx={{ p: 3 }}>
        <Stack
          spacing={3}
          direction={{ xs: "column", sm: "row" }}
          alignItems={{ xs: "flex-start", sm: "center" }}
          justifyContent={{ xs: "flex-start", sm: "space-between" }}
        >
          <TextField
            id="replyText"
            multiline
            rows={4}
            variant="outlined"
            value={pageReplyText}
            onChange={onPageReplyTextChange}
            disabled={!editPageReplyText}
            fullWidth
            sx={(theme) => ({
              flexGrow: 1,
              backgroundColor: editPageReplyText
                ? theme.palette.background.paper
                : theme.palette.grey[300],
              // resize: "vertical",
              // overflow: "auto",
            })}
          />

          {editPageReplyText ? (
            <Stack direction={{ xs: "row", sm: "column" }} spacing={2}>
              <Button
                size="small"
                startIcon={<Iconify icon="ion:save" />}
                onClick={handlePageReplyTextSave}
                disabled={!isPageReplyInputValid}
                sx={{
                  p: 2,
                  border: 1,
                  borderColor: theme.palette.grey[500],
                  minWidth: "125px",
                }}
              >
                Save
              </Button>
              <Button
                size="small"
                startIcon={<Iconify icon="typcn:cancel" />}
                onClick={() => {
                  setEditPageReplyText(false);
                  setPageReplyText(fbPage.replyText);
                }}
                sx={{
                  p: 2,
                  border: 1,
                  borderColor: theme.palette.grey[500],
                  minWidth: "125px",
                }}
              >
                Cancel
              </Button>
            </Stack>
          ) : (
            <Button
              size="small"
              startIcon={<Iconify icon="typcn:edit" />}
              onClick={() => setEditPageReplyText(true)}
              sx={{
                p: 2,
                border: 1,
                borderColor: theme.palette.grey[500],
                minWidth: "125px",
              }}
            >
              Edit
            </Button>
          )}
        </Stack>
        <Typography variant="caption" sx={{ color: theme.palette.grey[500] }}>
          Characters: {pageReplyText?.length}/2000
        </Typography>
      </Stack>
    </Card>
  );

  if (!fbPage) {
    //return <div>Loading...</div>; // or return null or some loading spinner
    return <Spinner />;
  }

  return (
    <Container maxWidth="lg">
      <Stack
        spacing={2}
        direction="column"
        alignItems="left"
        sx={{
          mb: { xs: 3, md: 5 },
          padding: "1rem",
        }}
      >
        <CustomBreadcrumbs
          heading="Growth"
          links={[
            {
              name: "Home",
              href: "/",
            },
            {
              name: "Growth",
            },
          ]}
        />

        {fbPage.registrationComplete ? (
          <>
            <>{renderSubscriptionLink} </>
            <>{renderReplyText}</>
          </>
        ) : (
          <Card>
            <CardHeader
              title="Complete Registration"
              avatar={
                <Avatar sx={{ bgcolor: "transparent", width: 30, height: 30 }}>
                  <Iconify
                    icon="oi:warning"
                    style={{ color: theme.palette.warning.main }}
                  />
                </Avatar>
              }
            />
            <Stack spacing={2.5} sx={{ p: 3 }}>
              <Typography>
                1. Send "1sendRegistration" to page:{" "}
                <Link
                  href={`https://m.me/${fbPage.pageId}?text=1sendRegistration`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {fbPage.pageName}
                </Link>
              </Typography>
              <Typography>2. Subscribe</Typography>
              <Typography>3. Refresh this page</Typography>
            </Stack>
          </Card>
        )}
      </Stack>
    </Container>
  );
}
