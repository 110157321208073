function initFacebookSdk() {
  return new Promise((resolve) => {
    // wait for facebook sdk to initialize before starting the react app
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: process.env.REACT_APP_FB_APP_ID,
        cookie: true,
        xfbml: true,
        version: process.env.REACT_APP_FB_API_VERSION, // Specify the Graph API version to use
      });

      resolve();

      // @note can add auto login here
      // auto authenticate with the api if already logged in with facebook
      // window.FB.getLoginStatus(({ authResponse, status }) => {
      //   if (status === "connected") {
      //     resolve();
      //   } else {
      //     // nothing happens if not connected
      //     // can try deleting localStorage to force login
      //     resolve();
      //   }
      // });
    };

    // load facebook sdk script
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  });
}

export default initFacebookSdk;
