import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { Stack, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import { useEffect, useState, useContext } from "react";
import { useRouter } from "../hooks";

import { createShopifyAPIKey } from "../helpers/apiPage";

import Iconify from "../components/iconify";
import FormProvider, { RHFTextField } from "../components/hook-form";

import { IPageContext } from "../types/page.type";
import PageContext from "../context/pageContext";

// ----------------------------------------------------------------------

export default function Welcome() {
  const router = useRouter();
  const { setPageId } = useContext(PageContext) as IPageContext;

  const shopifyUrl = localStorage.getItem("shopifyUrl") as string;
  const [pages, setPages] = useState<Record<string, string>>({});

  const FormSchema = Yup.object().shape({
    pageId: Yup.string().required("Page is required"),
  });

  const defaultValues = {
    pageId: "",
  };

  const methods = useForm({
    resolver: yupResolver(FormSchema),
    defaultValues,
  });

  useEffect(() => {
    setPages(JSON.parse(localStorage.getItem("connectedPages") as string));
  }, []);

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const handleShopifyConnectPage = handleSubmit(async (data) => {
    try {
      await createShopifyAPIKey({ pageId: data.pageId, shopifyUrl });

      localStorage.setItem("defaultPage", data.pageId);
      setPageId(data.pageId);
      localStorage.removeItem("shopifyUrl");

      router.push("/app/home");
    } catch (error) {}
  });

  const renderHead = (
    <Stack spacing={2} sx={{ mb: 5 }}>
      <Typography variant="h4">Connect {shopifyUrl}</Typography>
      <Typography variant="body1">
        Select Facebook Page to connect with
      </Typography>
    </Stack>
  );

  const renderForm = (
    // @TODO - disable pages that are already have shopify store
    <Stack spacing={2.5}>
      <RHFTextField
        name="pageId"
        select
        fullWidth
        SelectProps={{ native: true }}
      >
        <option value="" disabled>
          Select Page
        </option>
        {Object.entries(pages).map(([pageId, pageName]) => (
          <option key={pageId} value={pageId}>
            {pageName}
          </option>
        ))}
      </RHFTextField>
      <LoadingButton
        fullWidth
        color="inherit"
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
        endIcon={<Iconify icon="eva:arrow-ios-forward-fill" />}
        sx={{ justifyContent: "space-between", pl: 2, pr: 1.5 }}
      >
        Connect Page
      </LoadingButton>
    </Stack>
  );

  return (
    <>
      {renderHead}
      <FormProvider methods={methods} onSubmit={handleShopifyConnectPage}>
        {renderForm}
      </FormProvider>
    </>
  );
}
