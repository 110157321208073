import { useContext, useEffect, useState } from "react";
import PageContext from "../../context/pageContext";
import { logout } from "../../helpers/fbServices";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Drawer from "@mui/material/Drawer";
import TextField from "@mui/material/TextField";

import { useResponsive, useRouter, usePathname } from "../../hooks";

import Logo from "../../components/LogoNew";
import Scrollbar from "../../components/scrollbar";
import { NavSectionVertical } from "../../components/nav-section";
import Iconify from "../../components/iconify/iconify";

import { NAV } from "../config-layout";
import { useNavData } from "./config-navigation";

import { IPageContext } from "../../types/page.type";

// ----------------------------------------------------------------------

type Props = {
  openNav: boolean;
  onCloseNav: VoidFunction;
};

export default function NavVertical({ openNav, onCloseNav }: Props) {
  const router = useRouter();

  const pathname = usePathname();

  const lgUp = useResponsive("up", "lg");

  const navData = useNavData();

  const { pageId, setPageId } = useContext(PageContext) as IPageContext;
  const [pages, setPages] = useState<Record<string, string>>({});

  const handlePageChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    localStorage.setItem("defaultPage", e.target.value);
    setPageId(e.target.value);
  };

  useEffect(() => {
    setPages(JSON.parse(localStorage.getItem("connectedPages") as string));
  }, []);

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleLogout = () => {
    logout();
    router.push("/");
  };

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center", //https://mui.com/system/flexbox/
        },
      }}
    >
      <Logo
        sx={{
          justifyContent: "center",
          alignItems: "center",
          mt: 3,
          mb: 1,
          width: 80,
          height: 60,
        }}
      />
      <Box sx={{ flexGrow: 1, width: "100%" }}>
        <NavSectionVertical
          data={navData}
          slotProps={{
            subheader: {
              ...{
                display: "none",
              },
            },
          }}
        />
      </Box>

      {/********************Footer************************/}

      <Stack
        spacing={1}
        sx={{
          position: "sticky",
          width: 1,
          bottom: 0,
          p: 1,
        }}
      >
        <TextField
          select
          fullWidth
          SelectProps={{ native: true }}
          value={pageId}
          onChange={handlePageChange}
        >
          <option value="" disabled>
            Select Page
          </option>
          {Object.entries(pages).map(([pageId, pageName]) => (
            <option key={pageId} value={pageId}>
              {pageName}
            </option>
          ))}
        </TextField>
        <Button
          onClick={handleLogout}
          startIcon={
            <Iconify
              icon="material-symbols:logout"
              sx={{ transform: "scaleX(-1)" }}
            />
          }
        >
          Logout
        </Button>
      </Stack>
    </Scrollbar>
  );

  return (
    <Box
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_VERTICAL },
      }}
    >
      {lgUp ? (
        <Stack
          sx={{
            height: 1,
            position: "fixed",
            width: NAV.W_VERTICAL,
            borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          }}
        >
          {renderContent}
        </Stack>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          PaperProps={{
            sx: {
              width: NAV.W_VERTICAL,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
