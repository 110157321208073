const getBroadcasts = async ({
  pageId,
  page,
  PAGE_SIZE,
  isFlow,
}: {
  pageId: string;
  page: number;
  PAGE_SIZE: number;
  isFlow: boolean;
}) => {
  const response = await fetch(
    `/api/broadcast/${pageId}?page=${page}&limit=${PAGE_SIZE}&sort=-broadcastTime&isFlow=${isFlow}`
  );
  const data = await response.json();
  if (data.status === "fail") {
    throw new Error(data.message);
  }
  return data;
};

const getBroadcast = async ({
  pageId,
  broadcastId,
}: {
  pageId: string;
  broadcastId: string;
}) => {
  const response = await fetch(
    `/api/broadcast/${pageId}?broadcastId=${broadcastId}`
  );
  const data = await response.json();
  if (data.status === "fail") {
    throw new Error(data.message);
  }
  return data;
};

const sendBroadcast = async (formData: object) => {
  const response = await fetch("/api/broadcast", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formData),
  });
  const data = await response.json();
  return data;
};
const cancelBroadcast = async (broadcastId: string) => {
  const response = await fetch(`/api/broadcast/${broadcastId}`, {
    method: "DELETE",
  });
  const data = await response.json();
  return data;
};

const sendPreview = async (formData: object) => {
  try {
    const response = await fetch("/api/message/preview", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
  }
};

export {
  getBroadcasts,
  getBroadcast,
  sendBroadcast,
  cancelBroadcast,
  sendPreview,
};
