const loginUser = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => {
  const response = await fetch("/api/user/login", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email, password }),
  });
  const data = await response.json();
  if (data.status === "fail") {
    throw new Error(data.message);
  }
  return data;
};

const createUser = async ({
  name,
  email,
  password,
}: {
  name: string;
  email: string;
  password: string;
}) => {
  const response = await fetch("/api/user/signup", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ name, email, password }),
  });
  const data = await response.json();
  if (data.status === "fail") {
    throw new Error(data.message);
  }
  return data;
};

const getUser = async ({ token }: { token: string }) => {
  // not used
  const response = await fetch("/api/user/:token");
  const data = await response.json();
  if (data.status === "fail") {
    throw new Error(data.message);
  }
  return data;
};

const forgotPassword = async ({ email }: { email: string }) => {
  const response = await fetch("/api/user/forgotPassword", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email }),
  });
  const data = await response.json();
  if (data.status === "fail") {
    throw new Error(data.message);
  }
  return data;
};

const resetPassword = async ({
  token,
  password,
}: {
  token: string;
  password: string;
}) => {
  const response = await fetch(`/api/user/resetPassword/${token}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ password }),
  });
  const data = await response.json();
  if (data.status === "fail") {
    throw new Error(data.message);
  }
  return data;
};

const updateUserId = async ({
  fbUserId,
  oneUserId,
}: {
  fbUserId: string;
  oneUserId: string;
}) => {
  const response = await fetch("/api/user/updateUserId", {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ fbUserId, oneUserId }),
  });
  const data = await response.json();
  if (data.status === "fail") {
    throw new Error(data.message);
  }
  return data;
};

export {
  loginUser,
  createUser,
  forgotPassword,
  resetPassword,
  getUser,
  updateUserId,
};
