import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import { useContext, useState, useEffect } from "react";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { Box, CardHeader, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";

import Spinner from "../components/Spinner";
import Label from "../components/label";
import CustomBreadcrumbs from "../components/custom-breadcrumbs";

import { getBroadcast } from "../helpers/apiBroadcast";
import PageContext from "../context/pageContext";

import { IPageContext } from "../types/page.type";

type StatusProp = {
  statusText: string;
  statusColor:
    | "info"
    | "success"
    | "error"
    | "warning"
    | "default"
    | "primary"
    | "secondary";
};

export default function Broadcast() {
  const [status, setStatus] = useState({} as StatusProp);
  const { pageId } = useContext(PageContext) as IPageContext;

  const params = useParams();
  const broadcastId = params.broadcastId || "";

  // QUERY
  const { isLoading, data } = useQuery({
    queryKey: ["broadcast", pageId, broadcastId],
    queryFn: () => getBroadcast({ pageId, broadcastId }),
    retry: 1,
  });

  const broadcast = data?.data?.broadcasts[0];

  const {
    buttonUrl,
    buttonPrompt,
    broadcastName,
    fileLink,
    fileType,
    broadcastText,
    broadcastTime,
    broadcastStatus,
    broadcastRecipientCount,
    broadcastReadCount24Hour,
    broadcastReadCount7Day,
    broadcastClickCount,
  } = broadcast || {};

  //var broadcastTimeInLocale = new Date(broadcastTime).toLocaleString();
  //Apr 01, 2024 HH:mm AM/PM

  const broadcastTimeInLocale = new Date(broadcastTime).toLocaleDateString([], {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    timeZoneName: "short",
  });

  useEffect(() => {
    statusConvertFull(broadcastStatus);
  }, [broadcastStatus]);

  const statusConvertFull = (status: String) => {
    switch (status) {
      case "0":
        setStatus({
          statusText: "Draft",
          statusColor: "secondary",
        });
        break;
      case "1":
        setStatus({
          statusText: "Scheduled",
          statusColor: "info",
        });
        break;
      case "2":
        setStatus({
          statusText: "Sending",
          statusColor: "success",
        });
        break;
      case "3":
        setStatus({
          statusText: "Sent",
          statusColor: "success",
        });
        break;
      case "4":
        setStatus({
          statusText: "Canceled",
          statusColor: "error",
        });
        break;
      case "5":
        setStatus({
          statusText: "Ongoing",
          statusColor: "success",
        });
        break;
      default:
        setStatus({
          statusText: "Unknown",
          statusColor: "secondary",
        });
        break;
    }
  };

  const renderAnalytics = (
    <Card>
      <CardHeader title="Details" />

      <Stack spacing={2.5} sx={{ p: 3 }}>
        <Label variant="soft" sx={{ maxWidth: 100 }} color={status.statusColor}>
          {status.statusText}
        </Label>

        <Grid container spacing={0}>
          <Grid
            item
            xs={12}
            container
            spacing={0}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Typography variant="body2" color="textSecondary">
              Message delivered on:&nbsp;
            </Typography>
            <Typography variant="body2" color="textPrimary">
              {broadcastTimeInLocale}
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            container
            spacing={0}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Typography variant="body2" color="textSecondary">
              Recipients:&nbsp;
            </Typography>
            <Typography variant="body2" color="textPrimary">
              {broadcastRecipientCount}
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            container
            spacing={0}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Typography variant="body2" color="textSecondary">
              Read (24Hours):&nbsp;
            </Typography>
            <Typography variant="body2" color="textPrimary">
              {broadcastReadCount24Hour}
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            container
            spacing={0}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Typography variant="body2" color="textSecondary">
              Read (7Days):&nbsp;
            </Typography>
            <Typography variant="body2" color="textPrimary">
              {broadcastReadCount7Day}
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            container
            spacing={0}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Typography variant="body2" color="textSecondary">
              Unique Clicks:&nbsp;
            </Typography>
            <Typography variant="body2" color="textPrimary">
              {broadcastClickCount}
            </Typography>
          </Grid>
        </Grid>
      </Stack>
    </Card>
  );

  const renderSummary = (
    <Card>
      <CardHeader title={broadcastName} />

      <Stack spacing={2.5} sx={{ p: 3, alignItems: "center" }}>
        {fileType && (
          <Box
            component={fileType === "image" ? "img" : "video"}
            controls={fileType === "video"}
            src={fileLink}
            sx={{
              maxWidth: 350,
              height: "auto",
              objectFit: "contain",
              borderRadius: 1.5,
            }}
          />
        )}

        <Typography
          variant="body2"
          sx={{
            border: "1px solid #e0e0e0",
            p: 1.5,
            borderRadius: 1.5,
            width: 350,
          }}
        >
          {broadcastText}
        </Typography>

        {buttonPrompt && buttonUrl && (
          <>
            <Button
              variant="contained"
              color="primary"
              href={buttonUrl}
              target="_blank"
              sx={{ width: 350 }}
            >
              {buttonPrompt}
            </Button>
            <Button
              variant="text"
              color="primary"
              href={buttonUrl}
              target="_blank"
            >
              {buttonUrl}
            </Button>
          </>
        )}
      </Stack>
    </Card>
  );

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <Container maxWidth="lg">
        <Stack
          spacing={2}
          direction="column"
          alignItems="left"
          sx={{
            mb: { xs: 3, md: 5 },
            padding: "1rem",
          }}
        >
          <CustomBreadcrumbs
            heading="Campaigns"
            links={[
              {
                name: "Home",
                href: "/app/home",
              },
              {
                name: "Campaigns",
                href: "/app/campaign",
              },
              {
                name: broadcastName,
              },
            ]}
          />

          {renderSummary}
          {renderAnalytics}
        </Stack>
      </Container>
    </>
  );
}
