import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { DevTool } from "@hookform/devtools";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMemo, useState, useEffect, useCallback, useContext } from "react";
import PageContext from "../context/pageContext";
import { useQueryClient } from "@tanstack/react-query";

import { sendBroadcast, sendPreview } from "../helpers/apiBroadcast";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Unstable_Grid2";
import Container from "@mui/material/Container";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import FormControlLabel from "@mui/material/FormControlLabel";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CircularProgress from "@mui/material/CircularProgress";

import UploadWidget from "../components/UploadWidget";
import { useSnackbar } from "../components/snackbar";
import CustomBreadcrumbs from "../components/custom-breadcrumbs";

import { useRouter, useResponsive, useBoolean } from "../hooks";

import FormProvider, { RHFTextField } from "../components/hook-form";
import FilePreview from "../components/FilePreview";

import { IPageContext } from "../types/page.type";

// ----------------------------------------------------------------------

export default function TemplateFormNew() {
  const router = useRouter();

  const mdUp = useResponsive("up", "md");

  const { enqueueSnackbar } = useSnackbar();

  const formSubmitted = useBoolean();
  const preview = useBoolean();
  const showButton = useBoolean();

  const queryClient = useQueryClient();

  const [returnedBroadcastId, setReturnedBroadcastId] = useState("");
  const [uploadedFile, setUploadedFile] = useState<{
    fileName: string;
    fileFormat: string;
  } | null>(null);

  const { pageId } = useContext(PageContext) as IPageContext;
  const userId = localStorage.getItem("userId");

  useEffect(() => {
    setTimeout(() => window.FB.XFBML.parse(), 50);
  }, [preview]);

  const BroadcastSchema = Yup.object().shape({
    broadcastName: Yup.string().required("Name is required"),
    broadcastText: Yup.string().required("Text is required"),

    // Conditional Button Validation
    // showButton context returns an array for some reason
    buttonPrompt: Yup.string().when("$showButton", (showButton, schema) =>
      showButton[0] ? schema.required("Button title is required") : schema
    ),
    buttonUrl: Yup.string().when("$showButton", (showButton, schema) =>
      showButton[0] ? schema.required("Button title is required") : schema
    ),

    // not required
    broadcastTime: Yup.date(),
    fileType: Yup.string(),
    fileLink: Yup.string(),
    segmentId: Yup.string(),
  });

  const defaultValues = useMemo(
    () => ({
      broadcastName:
        "Campaign - " +
          new Date().toLocaleDateString("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
          }) || "",
      broadcastTime: new Date(),
      broadcastText:
        "Sample message for template. Will be overwritten by webhook message",
      pageId: pageId,
      fileType: "",
      fileLink: "",
      buttonPrompt: "",
      buttonUrl: "",
      userId: userId,
      segmentId: "",
      isFlow: true,
    }),
    [pageId, userId]
  );

  const methods = useForm({
    resolver: yupResolver(BroadcastSchema),
    defaultValues,
    context: { showButton: showButton.value },
  });

  const {
    watch,
    setValue,
    handleSubmit,
    resetField,
    control,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  const onSubmit = handleSubmit(async (data) => {
    try {
      const buttonArray = !!data.buttonPrompt
        ? [{ buttonPrompt: data.buttonPrompt, buttonUrl: data.buttonUrl }]
        : [];

      const { status, message, broadcastId } = await sendBroadcast({
        ...data,
        isScheduled: false,
        buttonArray,
      });
      if (status === "fail") {
        console.error(message);
      } else if (status === "success") {
        setReturnedBroadcastId(broadcastId);
        queryClient.invalidateQueries({ queryKey: ["broadcasts"] });
        formSubmitted.onTrue();
      }
    } catch (error) {
      console.error(error);
    }
  });

  const handlePreview = handleSubmit(async (data) => {
    try {
      const formData = {
        ...data,
        isScheduled: false,
        buttonArray: !!data.buttonPrompt
          ? [
              {
                buttonPrompt: data.buttonPrompt,
                buttonUrl: data.buttonUrl,
              },
            ]
          : [],
      };

      const previewResponse = await sendPreview(formData);
      if (
        previewResponse.status === "error"
        // @note could either 24 hrs passed or never connected contact w/ user
        // &&
        // previewResponse.message ===
        //   "(#10) This message is being sent outside the allowed window. Learn more about the new policy here: https://developers.facebook.com/docs/messenger-platform/policy-overview"
      ) {
        preview.onToggle();
      }
    } catch (error) {
      console.error(error);
    }
  });

  const handleButtonToggle = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!event.target.checked) {
        resetField("buttonPrompt");
        resetField("buttonUrl");
      }
      showButton.setValue(event.target.checked);
    },
    [resetField, showButton]
  );

  function handleOnUpload(error: any, result: any, widget: any) {
    if (error) {
      enqueueSnackbar(error.statusText, {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
      widget.close({
        quiet: true,
      });
      return;
    }
    setUploadedFile({
      fileName: result.info["original_filename"],
      fileFormat: result.info["format"],
    });
    setValue("fileType", result.info["resource_type"], {
      shouldDirty: true,
      shouldTouch: true,
    });
    setValue("fileLink", result.info["secure_url"], {
      shouldDirty: true,
      shouldTouch: true,
    });
  }

  const handleRemoveFile = useCallback(() => {
    // Not sure why resetField is not working
    setValue("fileType", "");
    setValue("fileLink", "");
    setUploadedFile(null);
  }, [setValue, setUploadedFile]);

  // Listens for opt-in event
  // @NOTE When send to messenger is clicked in succession, it will trigger the opt-in event compoundedly. Also form data is not updated in initial versions. This issue is avoided at handlePreview function.
  // @ts-ignore
  window.FB.Event.subscribe("send_to_messenger", async function (e) {
    // @ts-ignore
    if (e.event === "opt_in" && preview.value) {
      console.log(e);

      const formData = {
        ...values,
        isScheduled: false,
        buttonArray: !!values.buttonPrompt
          ? [
              {
                buttonPrompt: values.buttonPrompt,
                buttonUrl: values.buttonUrl,
              },
            ]
          : [],
      };

      await sendPreview(formData);
      preview.onFalse();
    }
  });

  const renderDetails = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            Details
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            What your subscribers will see
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title="Details" />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <RHFTextField
              name="broadcastName"
              label="Campaign Name *"
              InputLabelProps={{ shrink: true }}
            />

            <Stack spacing={0}>
              <UploadWidget onUpload={handleOnUpload} />
              {uploadedFile && (
                <Box>
                  <FilePreview
                    file={uploadedFile}
                    onRemove={handleRemoveFile}
                  />
                </Box>
              )}
            </Stack>

            <Stack spacing={1.5}>
              <FormControlLabel
                control={
                  <Switch
                    checked={showButton.value}
                    onChange={handleButtonToggle}
                  />
                }
                label="+ Add Button"
              />

              {showButton.value && (
                <>
                  <Stack spacing={1}>
                    <RHFTextField
                      name="buttonPrompt"
                      label="Button Title"
                      inputProps={{ maxLength: 20 }}
                      InputLabelProps={{ shrink: true }}
                    />
                    <Typography
                      variant="body2"
                      sx={{ color: "text.secondary" }}
                    >
                      Characters: {values.buttonPrompt?.length ?? 0}
                      /20
                    </Typography>
                  </Stack>

                  <RHFTextField
                    name="buttonUrl"
                    label="Button URL Link"
                    InputLabelProps={{ shrink: true }}
                  />
                </>
              )}
            </Stack>
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const renderActions = (
    <>
      {mdUp && <Grid md={4} />}
      <Stack>
        <Grid
          xs={12}
          md={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
          }}
        >
          <LoadingButton
            type="submit"
            variant="contained"
            size="large"
            color="primary"
            disabled={isSubmitting}
            sx={{ mr: 1 }}
          >
            Create Template
          </LoadingButton>
          <LoadingButton
            variant="outlined"
            size="large"
            color="primary"
            onClick={handlePreview}
            disabled={isSubmitting}
            sx={{ mr: 1 }}
          >
            Preview
          </LoadingButton>
          {isSubmitting && <CircularProgress />}
        </Grid>
      </Stack>
    </>
  );

  const renderPreviewDialog = (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={preview.value}
      onClose={preview.onFalse}
    >
      <DialogTitle sx={{ pb: 0 }}>Almost There</DialogTitle>
      <DialogContent>
        <Stack spacing={3} sx={{ py: 4 }}>
          <Typography variant="body1">
            Click "Send to Messenger" to preview the broadcast
          </Typography>
          <div
            //@ts-ignore - not sure how to set types for FB plugin
            messenger_app_id={process.env.REACT_APP_FB_APP_ID}
            className="fb-send-to-messenger"
            page_id={pageId}
            color="blue"
            size="large"
            data-ref={"prev-" + userId}
          ></div>

          <Typography variant="caption">
            We only ask you this when your last interaction with the page is
            more than 24 hours ago to keep compliant with Meta's messaging
            policies.
          </Typography>
        </Stack>
      </DialogContent>
    </Dialog>
  );

  const renderConfirmation = (
    <Card>
      <CardHeader title="Your template is ready" />
      <Stack spacing={3} sx={{ p: 3 }}>
        <Typography variant="body1">
          <strong>{returnedBroadcastId}</strong> is queued up and waiting to be
          sent.
        </Typography>
        <Button
          variant="contained"
          size="large"
          color="primary"
          onClick={() => router.push("/app/campaign")}
          sx={{
            p: 1,
            width: "145px",
          }}
        >
          Go to campaigns
        </Button>
      </Stack>
    </Card>
  );
  return (
    <Container maxWidth="lg">
      <Stack
        spacing={2}
        direction="column"
        alignItems="left"
        sx={{
          mb: { xs: 3, md: 5 },
          padding: "1rem",
        }}
      >
        <CustomBreadcrumbs
          heading="Template"
          links={[
            {
              name: "Home",
              href: "/",
            },
            {
              name: "Template",
            },
          ]}
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />

        {formSubmitted.value === false ? (
          <>
            <FormProvider methods={methods} onSubmit={onSubmit}>
              <Grid container spacing={3}>
                {renderDetails}
                {renderActions}
              </Grid>
              {renderPreviewDialog}
              <DevTool control={control} />
            </FormProvider>
          </>
        ) : (
          <>{renderConfirmation}</>
        )}
      </Stack>
    </Container>
  );
}
