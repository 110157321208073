import * as Yup from "yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Link from "@mui/material/Link";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import InputAdornment from "@mui/material/InputAdornment";

import { RouterLink } from "../helpers/router-link";
import { createUser } from "../helpers/apiAuth";

import { useRouter, useBoolean } from "../hooks";

import Iconify from "../components/iconify";
import FormProvider, { RHFTextField } from "../components/hook-form";

// ----------------------------------------------------------------------

export default function SignUpNew() {
  const router = useRouter();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const password = useBoolean();

  const RegisterSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .required("Email is required")
      .email("Email must be a valid email address"),
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters"),
  });

  const defaultValues = {
    name: "",
    email: "",
    password: "",
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      //   await register?.(data.email, data.password, data.firstName, data.lastName);
      //   router.push(returnTo || PATH_AFTER_LOGIN);

      const name = data.name;
      const email = data.email;
      const password = data.password;

      const res = await createUser({ name, email, password });
      const {
        data: {
          // token,
          user: { userId },
        },
      } = res;
      localStorage.setItem("userId", userId);
      // localStorage.setItem("token", token);
      //navigate("/connect");

      //router.push("/auth/connect");
      router.push("/connect");
    } catch (error: unknown) {
      if (typeof error === "object" && error !== null) {
        const err = error as { message?: string };
        if (err.message === "Email already exists!") {
          setErrorMessage(
            "Email already exists. Please use another email to create another account."
          );
        }
        console.error(error);
      } else {
        setErrorMessage("Something went wrong. Please try again.");
      }
    }
  });

  const renderHead = (
    <Stack spacing={2} sx={{ mb: 5, position: "relative" }}>
      <Typography variant="h4">Create your account</Typography>

      <Stack direction="row" spacing={0.5}>
        <Typography variant="body2"> Already have an account? </Typography>

        <Link
          component={RouterLink} //href="/auth/login-new"
          href="/"
          variant="subtitle2"
        >
          Sign in
        </Link>
      </Stack>
    </Stack>
  );

  const renderTerms = (
    <Typography
      component="div"
      sx={{
        mt: 2.5,
        textAlign: "center",
        typography: "caption",
        color: "text.secondary",
      }}
    >
      {"By signing up, I agree to "}
      <Link
        underline="always"
        component={RouterLink}
        href="https://www.1send.net/terms"
        color="text.primary"
        target="_blank"
        rel="noopener noreferrer"
      >
        Terms & Conditions
      </Link>
      {" and "}
      <Link
        underline="always"
        component={RouterLink}
        href="https://www.1send.net/privacy"
        color="text.primary"
        target="_blank"
        rel="noopener noreferrer"
      >
        Privacy Policy
      </Link>
      .
    </Typography>
  );

  const renderForm = (
    <Stack spacing={2.5}>
      <RHFTextField name="name" label="Name" />

      <RHFTextField name="email" label="Email address" />

      <RHFTextField
        name="password"
        label="Password (min. length 8)"
        type={password.value ? "text" : "password"}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={password.onToggle} edge="end">
                <Iconify
                  icon={
                    password.value ? "solar:eye-bold" : "solar:eye-closed-bold"
                  }
                />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <LoadingButton
        fullWidth
        color="inherit"
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
      >
        Sign up
      </LoadingButton>
      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
    </Stack>
  );

  return (
    <>
      {renderHead}

      <FormProvider methods={methods} onSubmit={onSubmit}>
        {renderForm}
      </FormProvider>

      {renderTerms}
    </>
  );
}
