const getConnectedPages = async ({ userId }: { userId: string }) => {
  const response = await fetch(`/api/page/user/${userId}`);
  const data = await response.json();
  if (data.status === "fail") {
    throw new Error(data.message);
  }
  return data;
};

const getPage = async ({ pageId }: { pageId: string }) => {
  const response = await fetch(`/api/page/${pageId}`);
  const data = await response.json();
  if (data.status === "fail") {
    throw new Error(data.message);
  }
  return data;
};

const updateReplyText = async ({
  pageId,
  replyText,
}: {
  pageId: string;
  replyText: string;
}) => {
  const response = await fetch(`/api/page/${pageId}/reply-text`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ replyText }),
  });
  const data = await response.json();
  if (data.status === "fail") {
    throw new Error(data.message);
  }
  return data;
};

const getApiKeys = async ({ pageId }: { pageId: string }) => {
  const response = await fetch(`/api/v2/page/${pageId}/apiKeys`);
  const data = await response.json();
  if (data.status === "fail") {
    throw new Error(data.message);
  }
  return data;
};

const createShopifyAPIKey = async ({
  pageId,
  shopifyUrl,
}: {
  pageId: string;
  shopifyUrl: string;
}) => {
  const response = await fetch(
    `/api/v2/page/${pageId}/createShopifyIntegration`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ shopifyUrl }),
    }
  );
  const data = await response.json();
  if (data.status === "fail") {
    throw new Error(data.message);
  }
  return data;
};

export {
  getConnectedPages,
  getPage,
  updateReplyText,
  getApiKeys,
  createShopifyAPIKey,
};
