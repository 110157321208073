import { m, AnimatePresence } from "framer-motion";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { alpha } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import ListItemText from "@mui/material/ListItemText";

import Iconify from "./iconify";
import { varFade } from "./animate";
import { fileThumb } from "./file-thumbnail/utils";

// ----------------------------------------------------------------------

export default function FilePreview({ file, onRemove, sx }: any) {
  const { fileName, fileFormat } = file;

  return (
    <AnimatePresence initial={false}>
      <Stack
        component={m.div}
        {...varFade().inUp}
        spacing={2}
        direction="row"
        alignItems="center"
        sx={{
          my: 1,
          py: 1,
          px: 1.5,
          borderRadius: 1,
          border: (theme) =>
            `solid 1px ${alpha(theme.palette.grey[500], 0.16)}`,
          ...sx,
        }}
      >
        <Box
          component="img"
          src={fileThumb(fileFormat)}
          sx={{
            width: 32,
            height: 32,
            flexShrink: 0,
            ...sx,
          }}
        />

        <ListItemText
          primary={fileName}
          secondary={fileFormat.toUpperCase()}
          secondaryTypographyProps={{
            component: "span",
            typography: "caption",
          }}
        />

        <IconButton size="small" onClick={onRemove}>
          <Iconify icon="mingcute:close-line" width={16} />
        </IconButton>
      </Stack>
    </AnimatePresence>
  );
}
